@import url(https://weloveiconfonts.com/api/?family=zocial);
@import url(https://weloveiconfonts.com/api/?family=entypo);
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap");

body {
  font-family: "Montserrat", sans-serif;

  background-color: #fefefe;
}

/* zocial */
[class*="zocial-"]::before {
  font-family: "zocial", sans-serif;
}
/* entypo */
[class*="entypo-"]:before {
  font-family: "entypo", sans-serif;
}

.wrapper {
  padding: 3em 1em 0em 1em;
  max-width: 1000px;
  display: flex;
  flex: 1;
  margin: 0 auto;
}

#name {
  font-weight: 300;
}

a {
  text-decoration: none;
  color: #eb5e28;
  font-weight: 500;

  &:activate {
    color: #cd4813;
  }
}

ul.publications {
  padding: 5px 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;

  li {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px 0;

    .title {
      font-size: 0.9em;
    }

    .links {
      display: flex;
      a {
        padding: 0 3px;
        text-decoration: none;
        color: #000000;
        &:activate {
          color: #000000;
        }
      }
    }
  }
}

ul.links {
  padding: 5px 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 20px;
    height: 20px;

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 10px;
      text-decoration: none;
      color: #000000;
      &:activate {
        color: #000000;
      }

      &:hover {
        transform: translateY(-5px) scale(1.1);
        transition: 0.3s ease-in-out;
      }

      .icon {
        font-size: 1.4em;
      }
    }
  }
}

.shape {
  img {
    width: 250px;
  }
  padding-left: 100px;
}

@media screen and (max-width: 900px) {
  .shape {
    img {
      width: 200px;
    }
    padding-left: 20px;
  }
}
@media screen and (max-width: 650px) {
  .shape {
    display: none;
  }
}
